import ItemList from "./item_list";

class ColourList {
  constructor(element) {
    this.element = element;
    this.itemList = new ItemList(this.element.querySelector("ul"));
    this.input = this.element.querySelector("input[type=hidden]");
    this.button = this.element.querySelector("button");

    this.itemList.addEventListener("select", this.onListSelect.bind(this));

    this.button.addEventListener("click", this.onInputClick.bind(this));
    this.button.addEventListener("keydown", this.onInputKeyDown.bind(this));
    this.button.addEventListener("keyup", this.onInputKeyUp.bind(this));

    document.addEventListener("click", this.onClickAnywhere.bind(this));

    this.itemList.selectById(this.input.value);
  }

  onClickAnywhere(event) {
    if (!this.element.contains(event.target)) { this.itemList.hide(); }
  }

  onDown(event) {
    if (this.itemList.isVisible()) {
      this.itemList.highlightNextOrFirst();
    } else {
      this.itemList.show();
    }
    this.itemList.focus();
  }

  onInputClick(event) {
    if (this.itemList.isVisible()) {
      this.itemList.hide();
    } else {
      this.itemList.show();
    }
  }

  onInputKeyDown(event) {
    switch (event.key) {
      case "Enter":
        if (!this.itemList.isVisible()) { return; }

        event.stopImmediatePropagation();
        event.preventDefault();

        if (this.itemList.visibleCount() == 1) { this.itemList.selectFirst(); }
        break;
      case "Tab":
        this.itemList.hide();
        break;
    }
  }

  onInputKeyUp(event) {
    switch (event.key) {
      case "Escape":
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowRight":
      case " ":
      case "Tab":
      case "Shift":
      case "Meta":
        // ignore otherwise the menu will show
        break;
      case "Enter":
        if (this.itemList.isVisible()) { this.itemList.hide(); }
        break;
      case "ArrowDown":
        this.onDown(event);
        break;
      default:
        this.onType(event);
    }
  }

  onListSelect(event) {
    this.element.querySelector(".selected-name").innerText = event.detail.name;
    this.element.querySelector(".selected-colour").setAttribute("aria-label", event.detail.name);
    this.element.querySelector(".selected-colour").style["background-color"] = event.detail.hex;
    this.input.value = event.detail.id;
  }
}

export default ColourList;
