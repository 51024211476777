// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "stylesheets/application.scss";
import ClipboardJS from "clipboard";

import Autocomplete from "./autocomplete";
import ColourList from "./colour_list";
import MultiList from "./multi_list";
import MultiSelect from "./multi_select";
import WorkflowSummaries from "./workflow-summaries";

import "images/home.png";
import "images/work-one.png";
import "images/work-two.png";

document.addEventListener("turbolinks:load", function() {
  var clipboard = new ClipboardJS(".clipboard");
  clipboard.on('success', function(e) {
    document.querySelector(".clipboard").setAttribute("data-clipboard-state", "success");

    e.clearSelection();

    setTimeout(function() {
      document.querySelector(".clipboard").setAttribute("data-clipboard-state", "initial");
    }, 5000);
  });

  var userMenuButton = document.getElementById("user-menu-button");
  var userMenu = document.getElementById("user-menu");

  if (userMenuButton != null) {
    userMenuButton.addEventListener("click", event => {
      userMenu.classList.toggle("hidden");
    });
  }

  var mobileMenu = document.getElementById("mobile-menu");
  var mobileMenuButton = document.getElementById("mobile-menu-button");
  var mobileMenuOpen = document.getElementById("mobile-menu-open");
  var mobileMenuClose = document.getElementById("mobile-menu-close");

  if (mobileMenuButton != null) {
    mobileMenuButton.addEventListener("click", event => {
      mobileMenuOpen.classList.toggle("hidden");
      mobileMenuOpen.classList.toggle("block");

      mobileMenuClose.classList.toggle("hidden");
      mobileMenuClose.classList.toggle("block");

      mobileMenu.classList.toggle("hidden");
    });
  }

  var deidentifiedCheckbox = document.getElementById("workflow_deidentified");
  var summaryField = document.getElementsByClassName("workflow_event_summary")[0];

  if (deidentifiedCheckbox != null) {
    function toggleSummary() {
      if (deidentifiedCheckbox.checked) {
        summaryField.classList.remove("hidden");
      } else {
        summaryField.classList.add("hidden");
      }
    }

    deidentifiedCheckbox.addEventListener("change", toggleSummary);
    toggleSummary();
  }

  var dayRulesCheckbox = document.getElementById("workflow_day_rule_filters");
  var dayRulesBox = document.getElementById("day-rules");

  if (dayRulesCheckbox != null) {
    function toggleRules() {
      if (dayRulesCheckbox.checked) {
        dayRulesBox.classList.remove("hidden");
      } else {
        dayRulesBox.classList.add("hidden");
      }
    }

    dayRulesCheckbox.addEventListener("change", toggleRules);
    toggleRules();
  }

  const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  function handleDayChange(day) {
    var dayCheckbox = document.getElementById(`workflow_${day}`);
    var startInput = document.getElementById(`workflow_${day}_start`);
    var endInput = document.getElementById(`workflow_${day}_end`);

    if (dayCheckbox != null) {
      function toggleDay() {
        startInput.disabled = !dayCheckbox.checked;
        endInput.disabled = !dayCheckbox.checked;
      }

      dayCheckbox.addEventListener("change", toggleDay);
      toggleDay();
    }
  }

  for (var i = 0; i < days.length; i++) {
    handleDayChange(days[i]);
  }

  function handleToggle(button) {
    var identifier = button.getAttribute("aria-controls");
    var answer = document.getElementById(identifier);
    var toggle = document.getElementById(`toggle-${identifier}`);

    function adjustDisplay() {
      if (button.getAttribute("aria-expanded") == "false") {
        answer.classList.add("hidden");
        toggle.setAttribute("aria-hidden", "true");
        toggle.classList.add("rotate-0");
        toggle.classList.remove("-rotate-180");
      } else {
        answer.classList.remove("hidden");
        toggle.setAttribute("aria-hidden", "false");
        toggle.classList.remove("rotate-0");
        toggle.classList.add("-rotate-180");
      }
    }

    button.addEventListener("click", function() {
      if (button.getAttribute("aria-expanded") == "false") {
        button.setAttribute("aria-expanded", "true");
      } else {
        button.setAttribute("aria-expanded", "false");
      }

      adjustDisplay();
    });

    adjustDisplay();
  }

  var togglers = document.getElementsByClassName("toggle-button");
  for (var i = 0; i < togglers.length; i++) {
    handleToggle(togglers[i]);
  }

  var timeZoneSelects = document.querySelectorAll("select.time_zone");
  for (var i = 0; i < timeZoneSelects.length; i++) {
    new Autocomplete(timeZoneSelects[i]);
  }

  var colourLists = document.querySelectorAll(".colour-list");
  for (var i = 0; i < colourLists.length; i++) {
    new ColourList(colourLists[i]);
  }

  var subscriptionMode = document.getElementById("subscription_mode");
  var organisationField = document.querySelector("div.subscription_organisation_id");
  var applyField = document.querySelector("div.subscription_apply");

  if (organisationField != null) {
    function subModeChange() {
      var options = subscriptionMode.querySelectorAll("option");
      for (var i = 0; i < options.length; i++) {
        if (options[i].selected && options[i].value == "organisation") {
          organisationField.classList.remove("hidden");
          applyField.classList.remove("hidden");
        } else {
          organisationField.classList.add("hidden");
          applyField.classList.add("hidden");
        }
      }
    }

    subscriptionMode.addEventListener("change", subModeChange);

    subModeChange();
  }

  var workflowForm = document.querySelector(".edit_workflow, .new_workflow");
  if (workflowForm) {
    new WorkflowSummaries();
  }

  var multiLists = document.querySelectorAll("div.multi-list");
  for (var i = 0; i < multiLists.length; i++) {
    new MultiList(multiLists[i]);
  }

  var multiSelects = document.querySelectorAll("div.multi-select");
  for (var i = 0; i < multiSelects.length; i++) {
    new MultiSelect(multiSelects[i]);
  }

  var accountProviderSelect = document.getElementById("account_provider");

  if (accountProviderSelect != null) {
    var googleAdvancedButton = document.getElementById("google-advanced-button");
    var microsoftAdvancedButton = document.getElementById("microsoft-advanced-button");

    function providerChange() {
      var options = accountProviderSelect.querySelectorAll("option");
      for (var i = 0; i < options.length; i++) {
        if (options[i].selected && options[i].value == "google") {
          googleAdvancedButton.classList.remove("hidden");
          microsoftAdvancedButton.classList.add("hidden");
        } else if (options[i].selected && options[i].value == "microsoft") {
          googleAdvancedButton.classList.add("hidden");
          microsoftAdvancedButton.classList.remove("hidden");
        }
      }
    }

    accountProviderSelect.addEventListener("change", providerChange);
    providerChange();
  }
});

Rails.start()
Turbolinks.start()
