import ColourList from "../colour_list";

class Item {
  constructor(option) {
    this.option = option;
    this.value = option.value;
    this.path = this.option.getAttribute("data-options-path") || "";
    this.element = this.createElement();

    this.element.querySelector("a").addEventListener("click", this.onClickRemove.bind(this));

    this.appendOptions();
  }

  appendOptions() {
    if (this.path.length === 0) { return }

    var div = document.querySelector(`.multi-select-option[data-cache-id='${this.value}']`);
    if (div) { return; }

    var xmlhttp = new XMLHttpRequest();
    var self = this;

    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState == XMLHttpRequest.DONE) { self.onResponse(xmlhttp); }
    };

    xmlhttp.open("GET", this.path);
    xmlhttp.send();
  }

  createElement() {
    var li = document.createElement("li");
    li.innerHTML = `
      <div class="multi-select-summary">
        <div class="multi-select-info">
          <div class="multi-select-label">${ this.option.innerText }</div>
          <div class="multi-select-detail">${ this.option.parentNode.label }</div>
        </div>
        <div>
          <a href="#" class="multi-select-remove">Remove</a>
        </div>
      </div>
    `;

    return li;
  }

  onClickRemove(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    var event = new CustomEvent("remove", {
      detail: {value: this.option.value},
      bubbles: false,
      cancelable: false
    });
    this.element.dispatchEvent(event);
  }

  onResponse(xmlhttp) {
    if (xmlhttp.status == 200) {
      if (xmlhttp.responseText.trim().length > 0) {
        var div = document.createElement("div");
        div.classList.add("multi-select-option");
        div.setAttribute("data-cache-id", this.value);
        div.innerHTML = xmlhttp.responseText;

        var colourLists = div.querySelectorAll(".colour-list");
        for (var i = 0; i < colourLists.length; i++) {
          new ColourList(colourLists[i]);
        }

        this.element.appendChild(div);
      }
    } else {
      throw new Error(`An error occurred while loading destination options for cache ${this.value}`);
    }
  }
}

export default Item;
