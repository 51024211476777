import Element from "../element"
import Option from "./option"

class OptionList {
  constructor(select) {
    this.select = select;
    this.options = [];
    this.text = "";

    this.listElement = new Element("ul", {
      "id": `${this.select.id}-options`, "role": "listbox", "class": "hidden"
    }).output();

    this.summaryElement = new Element("div", {
      "class": "visually-hidden", "role": "status", "aria-live": "polite"
    }).output();

    var optionElements = this.select.getElementsByTagName("option");
    for (var i = 0; i < optionElements.length; i++) {
      var option = new Option(optionElements[i]);
      this.options.push(option);

      if (option.isSelected()) { this.text = option.text; }
      option.addEventListener("select", this.onOptionSelect.bind(this));

      this.listElement.appendChild(option.element);
    }

    this.listElement.addEventListener("keydown", this.onKeyDown.bind(this));
  }

  addEventListener(type, block) {
    this.listElement.addEventListener(type, block);
  }

  filter(value) {
    for (var i = 0; i < this.options.length; i++) {
      this.options[i].toggle(value);
    }

    var visible = this.visibleCount();
    if (visible == 1) {
      this.summaryElement.innerText = `${visible} result available.`;
    } else {
      this.summaryElement.innerText = `${visible} results available.`;
    }
  }

  focus() {
    var highlighted = this.getOption(function(option) { return option.isHighlighted() });
    if (highlighted) {
      highlighted.focus();
    } else {
      var option = this.getOption(function(option) { return option.isVisible() });
      if (option) { option.focus(); }
    }
  }

  getNext(option) {
    var next = this.options[this.options.indexOf(option) + 1];

    if (next && next.isVisible()) {
      return next;
    } else if (next) {
      return this.getNext(next);
    } else {
      return null;
    }
  }

  getOption(block) {
    var boundBlock = block.bind(this);

    for (var i = 0; i < this.options.length; i++) {
      if (boundBlock(this.options[i])) { return this.options[i]; }
    }

    return null;
  }

  getPrevious(option) {
    var previous = this.options[this.options.indexOf(option) - 1];

    if (previous && previous.isVisible()) {
      return previous;
    } else if (previous) {
      return this.getPrevious(previous);
    } else {
      return null;
    }
  }

  hasMatch(value) {
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].text.toLowerCase() == value.toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  hasVisible() {
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].isVisible()) { return true; }
    }

    return false;
  }

  hide() {
    this.listElement.classList.add("hidden");

    var highlighted = this.getOption(function(option) { return option.isHighlighted(); });
    if (highlighted) { highlighted.unhighlight(); }
  }

  highlightNextOrFirst() {
    var highlighted = this.getOption(function(option) { return option.isHighlighted(); });
    if (highlighted) {
      if (this.isVisible()) { this.moveDown(highlighted); }
      return;
    }

    var first = this.getOption(function(option) { return option.isVisible(); });
    if (first) { first.highlight(); }
  }

  isVisible() {
    return !this.listElement.classList.contains("hidden");
  }

  moveDown(highlighted) {
    if (highlighted) {
      highlighted.unhighlight();
      var next = this.getNext(highlighted);
      if (next) {
        next.highlight();
        this.scrollTo(next);
      }
    }
  }

  moveUp(highlighted) {
    if (highlighted) {
      highlighted.unhighlight();
      var previous = this.getPrevious(highlighted);
      if (previous) {
        previous.highlight();
        this.scrollTo(previous);
      }
    } else {
      this.releaseFocus();
    }
  }

  onKeyDown(event) {
    var highlighted = this.getOption(function(option) { return option.isHighlighted(); });

    switch (event.key) {
      case "ArrowUp":
        this.moveUp(highlighted);
        event.stopImmediatePropagation();
        event.preventDefault();
        break;
      case "ArrowDown":
        this.moveDown(highlighted);
        event.stopImmediatePropagation();
        event.preventDefault();
        break;
      case "Enter":
        if (highlighted) { this.selectOption(highlighted); }
        event.stopImmediatePropagation();
        event.preventDefault();
        break;
      case " ":
        if (highlighted) { this.selectOption(highlighted); }
        break;
      case "Escape":
        this.hide();
        this.releaseFocus();
        break;
      case "Tab":
        this.hide();
        break;
      default:
        this.releaseFocus();
    }
  }

  onOptionSelect(event) {
    var option = event.detail.option;

    this.removeFilter();

    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i] === option) {
        var listHeight = this.listElement.offsetHeight;
        var optionTop = this.options[i].element.offsetTop;
        var optionHeight = this.options[i].element.offsetHeight;

        if (this.listElement.scrollTop + listHeight < optionTop + optionHeight) {
          this.listElement.scrollTop = optionTop + optionHeight - 50;
        }
      } else {
        this.options[i].unselect();
      }
    }

    var event = new CustomEvent("select", {
      detail: {text: option.text},
      bubbles: false,
      cancelable: false
    });
    this.listElement.dispatchEvent(event);

    this.hide();
    this.releaseFocus();
  }

  releaseFocus() {
    var event = new CustomEvent("focusout", {
      bubbles: false,
      cancelable: false
    });
    this.listElement.dispatchEvent(event);
  }

  removeFilter() {
    for (var i = 0; i < this.options.length; i++) {
      this.options[i].show();
    }
  }

  scrollTo(option) {
    this.listElement.scrollTop = option.element.offsetTop - (3 * option.element.offsetHeight);
  }

  selectOption(option) {
    if (option) { option.select(); }

    this.hide();
    this.releaseFocus();
  }

  selectByText(text) {
    var option = this.getOption(function(option) { return option.text.toLowerCase() == text.toLowerCase() });

    this.selectOption(option);
  }

  selectFirst() {
    var option = this.getOption(function(option) { return option.isVisible() });

    this.selectOption(option);
  }

  show() {
    this.listElement.classList.remove("hidden");

    var selected = this.getOption(function(option) { return option.isSelected() });
    if (selected) {
      selected.highlight();
      this.scrollTo(selected);
    } else {
      var first = this.getOption(function(option) { return option.isVisible(); });
      if (first) { first.highlight(); }
    }
  }

  visibleCount() {
    var visible = 0;
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].isVisible()) { visible++; }
    }

    return visible;
  }
}

export default OptionList;
