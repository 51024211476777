import Element from "../element";
import Item from "./item";

class MultiList {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.input = wrapper.querySelector("input");
    this.label = wrapper.querySelector("label");
    this.values = JSON.parse(this.input.value);

    this.input.classList.add("hidden");

    this.list = new Element("ul", {role: "list"}).output();

    for (var i = 0; i < this.values.length; i++) {
      var item = new Item(this.values[i]);
      this.list.appendChild(item.element);

      item.element.addEventListener("remove", this.onRemove.bind(this));
    }

    this.wrapper.appendChild(this.list);

    this.appender = new Element("div", {class: "multi-list-appender"}).output();
    this.appender.innerHTML = `
      <label for="${ this.input.id }-appender">Add Rule:</label>
      <input name="${ this.input.id }_appender" id="${ this.input.id }_appender" type="text" />
      <button type="button">Add</button>
    `;
    this.appenderInput = this.appender.querySelector("input");
    this.appenderButton = this.appender.querySelector("button");

    this.appenderButton.addEventListener("click", this.onClickAdd.bind(this));
    this.appenderInput.addEventListener("keydown", this.onKeyDown.bind(this));

    this.wrapper.appendChild(this.appender);
  }

  onChange() {
    var changeEvent = new CustomEvent("change");

    this.input.value = JSON.stringify(this.values);
    this.input.dispatchEvent(changeEvent);
  }

  onClickAdd(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    var newValue = this.appenderInput.value;
    if (newValue.trim().length === 0) {
      return;
    }

    this.values.push(newValue);

    var item = new Item(newValue);
    this.list.appendChild(item.element);

    item.element.addEventListener("remove", this.onRemove.bind(this));

    this.onChange();
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.onClickAdd(event);
    }
  }

  onRemove(event) {
    event.target.parentNode.removeChild(event.target);

    var index = this.values.indexOf(event.detail.value);
    this.values.splice(index, 1);

    this.onChange();
  }
}

export default MultiList;
