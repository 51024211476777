class Item {
  constructor(text) {
    this.text = text;
    this.element = this.createElement();

    this.element.querySelector("a").addEventListener("click", this.onClickRemove.bind(this));
  }

  createElement() {
    var li = document.createElement("li");
    li.innerHTML = `
      <div class="multi-list-summary">
        <div class="multi-list-info">
          <div class="multi-list-label">${ this.text }</div>
        </div>
        <div>
          <a href="#" class="multi-list-remove">Remove</a>
        </div>
      </div>
    `;

    return li;
  }

  onClickRemove(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    var event = new CustomEvent("remove", {
      detail: {value: this.text},
      bubbles: false,
      cancelable: false
    });
    this.element.dispatchEvent(event);
  }
}

export default Item;
