class Element {
  constructor(tag, attributes) {
    this.tag = tag;
    this.attributes = attributes;
  }

  createElement() {
    switch (this.tag) {
      case "svg":
      case "path":
        return document.createElementNS("http://www.w3.org/2000/svg", this.tag);
      default:
        return document.createElement(this.tag);
    }
  }

  output() {
    var element = this.createElement();

    for (const key in this.attributes) {
      switch (key) {
        case "class":
          element.classList.add(this.attributes[key]);
          break;
        case "innerText":
          element.innerText = this.attributes[key];
          break;
        case "value":
          element.value = this.attributes[key];
          break;
        default:
          element.setAttribute(key, this.attributes[key]);
      }
    }

    return element;
  }
}

export default Element;
