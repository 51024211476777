import Item from "./item"

class ItemList {
  constructor(list) {
    this.list = list;
    this.items = []

    var itemElements = this.list.querySelectorAll("li");
    for (var i = 0; i < itemElements.length; i++) {
      var item = new Item(itemElements[i]);
      this.items.push(item);

      if (item.isSelected()) {
        this.text = item.text;
      }
      item.addEventListener("select", this.onItemSelect.bind(this));
      item.addEventListener("highlight", this.onItemHighlight.bind(this));
    }

    this.list.addEventListener("keydown", this.onKeyDown.bind(this));
  }

  addEventListener(type, block) {
    this.list.addEventListener(type, block);
  }

  focus() {
    var highlighted = this.getItem(function(item) { return item.isHighlighted() });
    if (highlighted) {
      highlighted.focus();
    } else {
      var item = this.items[0]
      if (item) { item.focus(); }
    }
  }

  getNext(item) {
    var next = this.items[this.items.indexOf(item) + 1];

    if (next) {
      return next;
    } else {
      return null;
    }
  }

  getItem(block) {
    var boundBlock = block.bind(this);

    for (var i = 0; i < this.items.length; i++) {
      if (boundBlock(this.items[i])) { return this.items[i]; }
    }

    return null;
  }

  getPrevious(item) {
    var previous = this.items[this.items.indexOf(item) - 1];

    if (previous) {
      return previous;
    } else {
      return null;
    }
  }

  hasMatch(value) {
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i].text.toLowerCase() == value.toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  hide() {
    this.list.classList.add("hidden");

    var highlighted = this.getItem(function(item) { return item.isHighlighted(); });
    if (highlighted) { highlighted.unhighlight(); }
  }

  highlightNextOrFirst() {
    var highlighted = this.getItem(function(item) { return item.isHighlighted(); });
    if (highlighted) {
      if (this.isVisible()) { this.moveDown(highlighted); }
      return;
    }

    var first = this.items[0];
    if (first) { first.highlight(); }
  }

  isVisible() {
    return !this.list.classList.contains("hidden");
  }

  moveDown(highlighted) {
    if (highlighted) {
      highlighted.unhighlight();
      var next = this.getNext(highlighted);
      if (next) {
        next.highlight();
        this.scrollTo(next);
      }
    }
  }

  moveUp(highlighted) {
    if (highlighted) {
      highlighted.unhighlight();
      var previous = this.getPrevious(highlighted);
      if (previous) {
        previous.highlight();
        this.scrollTo(previous);
      }
    } else {
      this.releaseFocus();
    }
  }

  onKeyDown(event) {
    var highlighted = this.getItem(function(item) { return item.isHighlighted(); });

    switch (event.key) {
      case "ArrowUp":
        this.moveUp(highlighted);
        event.stopImmediatePropagation();
        event.preventDefault();
        break;
      case "ArrowDown":
        this.moveDown(highlighted);
        event.stopImmediatePropagation();
        event.preventDefault();
        break;
      case "Enter":
        if (highlighted) { this.selectItem(highlighted); }
        event.stopImmediatePropagation();
        event.preventDefault();
        break;
      case " ":
        if (highlighted) { this.selectItem(highlighted); }
        break;
      case "Escape":
        this.hide();
        this.releaseFocus();
        break;
      case "Tab":
        this.hide();
        break;
      default:
        this.releaseFocus();
    }
  }

  onItemHighlight(event) {
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i] !== event.detail.item) {
        this.items[i].unhighlight();
      }
    }
  }

  onItemSelect(event) {
    var item = event.detail.item;

    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i] === item) {
        var listHeight = this.list.offsetHeight;
        var itemTop = this.items[i].item.offsetTop;
        var itemHeight = this.items[i].item.offsetHeight;

        if (this.list.scrollTop + listHeight < itemTop + itemHeight) {
          this.list.scrollTop = itemTop + itemHeight - 50;
        }
      } else {
        this.items[i].unselect();
      }
    }

    var event = new CustomEvent("select", {
      detail: {
        id: item.colour_id,
        name: item.colour_name,
        hex: item.colour_hex
      },
      bubbles: false,
      cancelable: false
    });
    this.list.dispatchEvent(event);

    this.hide();
    this.releaseFocus();
  }

  releaseFocus() {
    var event = new CustomEvent("focusout", {
      bubbles: false,
      cancelable: false
    });
    this.list.dispatchEvent(event);
  }

  scrollTo(item) {
    this.list.scrollTop = item.item.offsetTop - (3 * item.item.offsetHeight);
  }

  selectById(id) {
    this.selectItem(
      this.getItem(function(item) { return item.colour_id === id })
    );
  }

  selectItem(item) {
    if (item) { item.select(); }

    this.hide();
    this.releaseFocus();
  }

  selectFirst() {
    this.selectItem(this.items[0]);
  }

  show() {
    this.list.classList.remove("hidden");

    var selected = this.getItem(function(item) { return item.isSelected() });
    if (selected) {
      selected.highlight();
      this.scrollTo(selected);
    } else {
      var first = this.items[0];
      if (first) { first.highlight(); }
    }
  }
}

export default ItemList;
