import Element from "../element";
import OptionList from "./option_list";

class Autocomplete {
  constructor(select) {
    this.select = select;
    this.options = [];
    this.label = null;

    var labels = document.getElementsByTagName("label");
    for (var i = 0; i < labels.length; i++) {
      if (labels[i].getAttribute("for") == select.getAttribute("id")) {
        this.label = labels[i];
      }
    }

    this.wrapper = new Element("div", {"class": "autocomplete"}).output();

    this.input = new Element("input", {
      "id": `${this.select.id}-input`,
      "type": "text",
      "autocapitalize": "none",
      "autocomplete": "off",
      "role": "combobox",
      "aria-autocomplete": "list",
      "aria-expanded": "false",
      "aria-owns": `${this.select.id}-options`
    }).output();
    this.wrapper.appendChild(this.input);

    const svgWrapper = new Element("span", {"class": "toggle"}).output();

    const svg = new Element("svg", {
      "focusable": "false",
      "version": "1.1",
      "viewBox": "0 0 20 20",
      "fill": "currentColor",
      "aria-hidden": "true"
    }).output();

    const path = new Element("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
    }).output();
    svg.appendChild(path);
    svgWrapper.appendChild(svg);
    this.wrapper.appendChild(svgWrapper);

    this.optionList = new OptionList(this.select);
    this.wrapper.appendChild(this.optionList.listElement);
    this.wrapper.appendChild(this.optionList.summaryElement);

    this.optionList.addEventListener("focusout", this.onListFocusOut.bind(this));
    this.optionList.addEventListener("select", this.onListSelect.bind(this));

    this.input.value = this.optionList.text;

    this.label.setAttribute("for", `${this.select.id}-input`);

    this.select.classList.add("visually-hidden");
    this.select.setAttribute("aria-hidden", "true");
    this.select.setAttribute("tabindex", "-1");

    this.input.addEventListener("click", this.onInputClick.bind(this));
    this.input.addEventListener("keydown", this.onInputKeyDown.bind(this));
    this.input.addEventListener("keyup", this.onInputKeyUp.bind(this));

    document.addEventListener("click", this.onClickAnywhere.bind(this));

    this.select.parentNode.appendChild(this.wrapper);
  }

  onClickAnywhere(event) {
    if (!this.wrapper.contains(event.target)) { this.optionList.hide(); }
  }

  onDown(event) {
    var value = this.input.value.trim();

    if (value.length == 0 || this.optionList.hasMatch(value)) {
      this.optionList.removeFilter();
    } else {
      this.optionList.filter(value.toLowerCase());
      if (!this.optionList.hasVisible()) { return; }
    }

    if (this.optionList.isVisible()) {
      this.optionList.highlightNextOrFirst();
    } else {
      this.optionList.show();
    }
    this.optionList.focus();
  }

  onInputClick(event) {
    this.optionList.show();
  }

  onInputKeyDown(event) {
    switch (event.key) {
      case "Enter":
        if (!this.optionList.isVisible()) { return; }

        event.stopImmediatePropagation();
        event.preventDefault();

        if (this.optionList.visibleCount() == 1) { this.optionList.selectFirst(); }
        break;
      case "Tab":
        this.optionList.hide();
        break;
    }
  }

  onInputKeyUp(event) {
    switch (event.key) {
      case "Escape":
      case "ArrowUp":
      case "ArrowLeft":
      case "ArrowRight":
      case " ":
      case "Tab":
      case "Shift":
      case "Meta":
        // ignore otherwise the menu will show
        break;
      case "Enter":
        if (this.optionList.isVisible()) { this.optionList.hide(); }
        break;
      case "ArrowDown":
        this.onDown(event);
        break;
      default:
        this.onType(event);
    }
  }

  onListFocusOut(event) {
    if (this.input === document.activeElement) { return; }

    this.input.focus();
  }

  onListSelect(event) {
    if (this.input.value != event.detail.text) {
      this.input.value = event.detail.text;
    }
  }

  onType(event) {
    var value = this.input.value.trim().toLowerCase();

    if (value.length > 0) {
      this.optionList.filter(value);
      this.optionList.show();
    } else {
      this.optionList.hide();
    }

    this.updateSelect();
  }

  updateSelect() {
    if (this.optionList.hasMatch(this.input.value)) {
      this.optionList.selectByText(this.input.value);
    }
  }
}

export default Autocomplete;
