class Item {
  constructor(item) {
    this.item = item;
    this.colour_id = this.item.getAttribute("data-colour-id");
    this.colour_name = this.item.getAttribute("data-colour-name");
    this.colour_hex = this.item.getAttribute("data-colour-hex");

    this.addEventListener("click", this.onClick.bind(this));
    this.addEventListener("mouseenter", this.onEnter.bind(this));
    this.addEventListener("mouseleave", this.onLeave.bind(this));
  }

  addEventListener(type, block) {
    this.item.addEventListener(type, block);
  }

  focus() {
    this.item.focus();
  }

  highlight() {
    this.item.classList.add("highlighted");

    var event = new CustomEvent("highlight", {
      detail: {item: this},
      bubbles: false,
      cancelable: false
    });
    this.item.dispatchEvent(event);
  }

  isHighlighted() {
    return this.item.classList.contains("highlighted");
  }

  isSelected() {
    return this.item.selected;
  }

  onClick(event) {
    this.select();
  }

  onEnter(event) {
    this.highlight();
  }

  onLeave(event) {
    this.unhighlight();
  }

  select() {
    this.item.setAttribute("aria-selected", "true");
    this.item.classList.add("selected");
    this.item.selected = true;

    var event = new CustomEvent("select", {
      detail: {item: this},
      bubbles: false,
      cancelable: false
    });
    this.item.dispatchEvent(event);

    this.item.focus();
  }

  unhighlight() {
    this.item.classList.remove("highlighted");
  }

  unselect() {
    this.item.setAttribute("aria-selected", "false");
    this.item.classList.remove("selected");
    this.item.selected = false;
  }
}

export default Item;
