import Element from "../element";
import Item from "./item";

class MultiSelect {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.select = wrapper.querySelector("select");
    this.label = wrapper.querySelector("label");
    this.options = this.select.querySelectorAll("option");

    this.select.classList.add("hidden");
    this.label.classList.add("hidden");

    this.emptyState = new Element("div", {class: "multi-select-empty"}).output();
    this.emptyState.innerHTML = `
      <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
      </svg>
      <div>
        <h5>No calendars have been selected</h5>
        <p>Please choose from the list below.</p>
      </div>
    `;
    this.wrapper.appendChild(this.emptyState);

    this.list = new Element("ul", {role: "list"}).output();

    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].selected) {
        var item = new Item(this.options[i]);
        this.list.appendChild(item.element);

        item.element.addEventListener("remove", this.onRemove.bind(this));
      }
    }

    this.wrapper.appendChild(this.list);

    this.appender = new Element("div", {class: "multi-select-appender"}).output();
    this.appender.innerHTML = `
      <label for="${ this.select.id }-appender">Add:</label>
      <select name="${ this.select.id }_appender" id="${ this.select.id }_appender"></select>
      <button type="button">Add</button>
    `;
    this.appenderSelect = this.appender.querySelector("select");
    this.appenderButton = this.appender.querySelector("button");

    this.appenderButton.addEventListener("click", this.onClickAdd.bind(this));
    this.appenderSelect.addEventListener("keydown", this.onKeyDown.bind(this));

    this.updateAppenderOptions();

    this.wrapper.appendChild(this.appender);
  }

  onChange() {
    var changeEvent = new CustomEvent("change");
    this.select.dispatchEvent(changeEvent);

    this.updateAppenderOptions();
  }

  onClickAdd(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    var appenderOptions = this.appenderSelect.querySelectorAll("option");
    var chosenValue = null;

    for (var i = 0; i < appenderOptions.length; i++) {
      if (appenderOptions[i].selected) {
        chosenValue = appenderOptions[i].value;
      }
    }

    if (chosenValue === null) {
      return;
    }

    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].value == chosenValue) {
        this.options[i].selected = true;

        var item = new Item(this.options[i]);
        this.list.appendChild(item.element);

        item.element.addEventListener("remove", this.onRemove.bind(this));
      }
    }

    this.onChange();
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.onClickAdd(event);
    }
  }

  onRemove(event) {
    var value = event.detail.value;
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].value === value) {
        this.options[i].selected = false;
      }
    }

    event.target.parentNode.removeChild(event.target);

    this.onChange();
  }

  updateAppenderOptions() {
    this.appenderSelect.innerHTML = this.select.innerHTML;

    var selectedValues = [];
    for (var i = 0; i < this.options.length; i++) {
      if (this.options[i].selected) {
        selectedValues.push(this.options[i].value);
      }
    }

    var appenderOptions = this.appenderSelect.querySelectorAll("option");
    for (var i = 0; i < appenderOptions.length; i++) {
      if (selectedValues.includes(appenderOptions[i].value)) {
        appenderOptions[i].parentNode.removeChild(appenderOptions[i]);
      }
    }

    var appenderGroups = this.appenderSelect.querySelectorAll("optgroup");
    for (var i = 0; i < appenderGroups.length; i++) {
      if (appenderGroups[i].querySelectorAll("option").length === 0) {
        this.appenderSelect.removeChild(appenderGroups[i]);
      }
    }

    if (this.list.querySelectorAll("li").length === 0) {
      this.emptyState.classList.remove("not-empty");
    } else {
      this.emptyState.classList.add("not-empty");
    }
  }
}

export default MultiSelect;
