class WorkflowSummaries {
  constructor() {
    var inputs = document.querySelectorAll("input, select");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener("change", this.onChange.bind(this));
    }

    this.onChange(null);
  }

  onChange(event) {
    this.stepOne();
    this.stepTwo();
    this.stepThree();
    this.stepFour();
  }

  stepOne() {
    var step = document.getElementById("form-step-1");
    var detail = step.querySelector(".label-detail");
    var input = step.querySelector("#workflow_label");

    detail.innerText = input.value;
  }

  stepTwo() {
    var step = document.getElementById("form-step-2");
    var detail = step.querySelector(".label-detail");
    var options = step.querySelectorAll("#workflow_source_cache_ids option");
    var detailTexts = []

    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        detailTexts.push(options[i].innerText);
      }
    }

    if (detailTexts.length > 0) {
      detail.innerText = `Selected: ${ detailTexts.sort().join(", ") }`;
    } else {
      detail.innerText = `Selected: (none)`;
    }
  }

  stepThree() {
    var step = document.getElementById("form-step-3");
    var detail = step.querySelector(".label-detail");
    var options = step.querySelectorAll("#workflow_destination_cache_ids option");
    var detailTexts = []

    for (var i = 0; i < options.length; i++) {
      if (options[i].selected) {
        detailTexts.push(options[i].innerText);
      }
    }

    if (detailTexts.length > 0) {
      detail.innerText = `Selected: ${ detailTexts.sort().join(", ") }`;
    } else {
      detail.innerText = `Selected: (none)`;
    }
  }

  stepFour() {
    var step = document.getElementById("form-step-4");
    var detail = step.querySelector(".label-detail");
    var notes = [];

    if (step.querySelector("#workflow_filters") && JSON.parse(step.querySelector("#workflow_filters").value).length > 0) {
      notes.push("filter by event names");
    }

    if (step.querySelector("#workflow_deidentified").checked) {
      notes.push("redact details");
    }

    if (!step.querySelector("#workflow_keep_changes").checked) {
      notes.push("override changes");
    }

    if (!step.querySelector("#workflow_timed_events").checked) {
      notes.push("exclude timed events");
    }

    if (step.querySelector("#workflow_all_day_events").checked) {
      notes.push("include all-day events");
    }

    if (step.querySelector("#workflow_free_events").checked) {
      notes.push("include non-busy events");
    }

    if (step.querySelector("#workflow_tentative_events").checked) {
      notes.push("include tentative events");
    }

    if (step.querySelector("#workflow_day_rule_filters").checked) {
      notes.push("filter by days/times");
    }

    if (notes.length > 0) {
      var text = notes.join(", ");
      detail.innerText = text[0].toUpperCase() + text.substring(1);
    } else {
      detail.innerText = "(none)";
    }
  }
}

export default WorkflowSummaries;
