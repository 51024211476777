import Element from "../element";

class Option {
  constructor(option) {
    this.option = option;
    this.text = this.option.innerText;
    this.alt = this.option.getAttribute("data-alt");
    this.element = this.createElement();

    this.addEventListener("click", this.onClick.bind(this));
    this.addEventListener("mouseenter", this.onEnter.bind(this));
    this.addEventListener("mouseleave", this.onLeave.bind(this));
  }

  addEventListener(type, block) {
    this.element.addEventListener(type, block);
  }

  createElement() {
    var listOption = new Element("li", {
      "role": "option",
      "tabindex": "-1",
      "aria-selected": this.option.selected ? "true" : "false",
      "data-option-value": this.option.value
    }).output();

    if (this.option.selected) {
      listOption.classList.add("selected");
    }

    var optionLabel = new Element("span", {
      "class": "autocomplete-option-label", "innerText": this.text
    }).output();
    listOption.appendChild(optionLabel);

    var optionCheck = new Element("span", {"class": "autocomplete-option-check"}).output();
    var optionSVG = new Element("svg", {
      "viewBox": "0 0 20 20", "fill": "currentColor", "aria-hidden": "true"
    }).output();
    var path = new Element("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
    }).output();

    optionSVG.appendChild(path);
    optionCheck.append(optionSVG);
    listOption.appendChild(optionCheck);

    return listOption;
  }

  focus() {
    this.element.focus();
  }

  hide() {
    this.element.classList.add("hidden");
  }

  highlight() {
    this.element.classList.add("highlighted");
  }

  isHighlighted() {
    return this.element.classList.contains("highlighted");
  }

  isSelected() {
    return this.option.selected;
  }

  isVisible() {
    return !this.element.classList.contains("hidden");
  }

  onClick(event) {
    this.select();
  }

  onEnter(event) {
    this.highlight();
  }

  onLeave(event) {
    this.unhighlight();
  }

  select() {
    this.element.setAttribute("aria-selected", "true");
    this.element.classList.add("selected");
    this.option.selected = true;

    var event = new CustomEvent("select", {
      detail: {option: this},
      bubbles: false,
      cancelable: false
    });
    this.element.dispatchEvent(event);

    if (this.isVisible()) { this.element.focus(); }
  }

  show() {
    this.element.classList.remove("hidden");
  }

  toggle(inputValue) {
    if (this.text.toLowerCase().indexOf(inputValue) > -1 || (this.alt && this.alt.toLowerCase().indexOf(inputValue) > -1)) {
      this.show();
    } else {
      this.hide();
    }
  }

  unhighlight() {
    this.element.classList.remove("highlighted");
  }

  unselect() {
    this.element.setAttribute("aria-selected", "false");
    this.element.classList.remove("selected");
    this.option.selected = false;
  }
}

export default Option;
